<div class="layout">
  <app-header title="Módulo principal de Medicina Laboral" header></app-header>
  <section class="flex flex-row">
    <div class="aside">
      <app-sidebar
        *ngIf="userMenu"
        [sidebarVisible]="sidebarVisible"
        (toggle)="sidebarVisible = $event">
        <app-main-menu
          *ngIf="userMenu"
          [menu]="userMenu"
          side-content></app-main-menu>
      </app-sidebar>
    </div>
    <main class="main" [ngClass]="{ 'main--sidebar-visible': sidebarVisible }">
      <router-outlet content></router-outlet>
    </main>
  </section>
</div>
