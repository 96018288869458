import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import { IdentityUser } from './identity-user';
import { Constants } from '../utils/constants';
import { environment } from '../../../environments/environment';
import { MenuHeader } from '../menu';

/**
 *  Servicio usado para gestionar la seguridad del usuario
 */
@Injectable({
  providedIn: 'root',
})
export class IdentityService {
  API_URL: string = `${environment.BACKEND_URL}`;
  user: IdentityUser;

  constructor(private httpClient: HttpClient) {}

  /**
   * Esta función se encarga de enviar por el authState el usuario, ya sea que lo recupere del sessionStorage o del servicio
   * expuesto en el backend
   */
  getUserInfo(): Observable<IdentityUser> {
    return this.httpClient
      .get<IdentityUser>(`${this.API_URL}/${Constants.userInfo}`)
      .pipe(
        tap((user: IdentityUser) => {
          this.user = user;
        })
      );
  }

  /**
   * Con esta funcion se puede obtener el valor LoggedInYet o el formulario de login enviado por el sistema de seguridad
   *
   * @returns devuelve el html que representa el formulario de login
   */
  checkStatus(): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.API_URL}/${Constants.userCheckStatus}`
    );
  }

  /**
   * Con esta funcion se puede comprobar si el usuario en la sesión tiene permiso hacia un recurso especifico
   *
   * @param resource url del recurso
   * @returns indica si un usuario tiene acceso a un recurso específico
   */
  isAuthorized(resource: string): Observable<boolean> {
    const body = { method: 'GET', path: resource };
    return this.httpClient.post<boolean>(
      `${this.API_URL}/${Constants.userHasAccess}`,
      body
    );
  }

  getLastAccess(): Observable<string> {
    return this.httpClient.get<string>(
      `${this.API_URL}/${Constants.userLastAccess}`
    );
  }

  getUserMenu(): Observable<MenuHeader[]> {
    return this.httpClient.get<any>(`${this.API_URL}/${Constants.menu}`);
  }

  logout() {
    return this.httpClient.get(`${this.API_URL}/${Constants.logout}`, {
      responseType: 'text',
    });
  }
}
