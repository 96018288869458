import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpErrorComponent } from './shared/components/http-error/http-error.component';
import { HttpInterceptRequestService } from './core/http-interceptors';
import { AuthorizationGuard } from './core/security';
import { LayoutComponent } from './layout/components/layout/layout.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'bandejas',
        loadChildren: () =>
          import('./inboxes/inboxes.module').then(m => m.InboxesModule),
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'direccionamiento-empresas',
        loadChildren: () =>
          import('./companies-addressing/companies-addressing.module').then(
            m => m.CompaniesAddressingModule
          ),
        canActivate: [AuthorizationGuard],
      },
    ],
  },
  {
    path: 'access-denied',
    component: HttpErrorComponent,
    data: { errorType: 'forbidden' },
  },
  {
    path: 'not-found',
    component: HttpErrorComponent,
    data: { errorType: 'not-found' },
  },
  {
    path: 'internal-server-error',
    component: HttpErrorComponent,
    data: { errorType: 'internal-server-error' },
  },

  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    SharedModule,
    RouterModule.forRoot(APP_ROUTES),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptRequestService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
