import { NgModule } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    SidebarComponent,
    MainMenuComponent,
  ],
  imports: [
    ButtonModule,
    MenuModule,
    NgClass,
    NgIf,
    PanelMenuModule,
    ScrollPanelModule,
    FontAwesomeModule,
  ],
  exports: [LayoutComponent, HeaderComponent, MainMenuComponent],
})
export class LayoutModule {}
