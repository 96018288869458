<header *ngIf="user" class="header">
  <h2 class="header__title">
    {{ title }}
  </h2>

  <div class="header__user">
    <p-menu #menu [model]="menuItems" [popup]="true"></p-menu>
    <p-button
      styleClass="p-button-primary p-button-rounded p-button-outlined p-button-raised"
      [style]="{ color: 'white' }"
      (click)="menu.toggle($event)"
      iconPos="right"
      [label]="user.fullName">
      <fa-icon [icon]="faGear" class="mr-1"></fa-icon>
    </p-button>
  </div>
</header>
