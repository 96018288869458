<div *ngIf="errorData" class="error">
  <i class="error__icon fa-regular fa-circle-xmark"></i>
  <h2 *ngIf="errorData.code" class="error__code">Error {{ errorData.code }}</h2>
  <h2 class="error__title">{{ errorData.title }}</h2>
  <p class="error__description">
    {{ errorData.description }}
  </p>
  <p-button
    class="error__button"
    styleClass="p-button-rounded"
    (click)="navigateToHome()">
    Volver al inicio
  </p-button>
</div>
