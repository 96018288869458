import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { IdentityService } from './identity.service';
import { inject } from '@angular/core';

/**
 * Function Guard cuyo fin es la de ejercer la autenticación y la autorizacion sobre las urls del sistema de enrutamiento de
 * angular.
 */
export const AuthorizationGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  /**
   * @param seusService servicio que se conectará con el backend para validar seguridad
   */
  const seusService = inject(IdentityService);
  /**
   * @param router objeto para controlar el enrutamiento de la aplicación
   */
  const router = inject(Router);

  /**
   * realiza la comprobación de autenticación e invoca la autorización de ser necesario
   *
   * @param state representa el estado del router en un momento de tiempo
   * @returns Promise booleano donde si es True si esta autenticado o si se permite el acceso a la url.
   */
  const doActivate = (state: RouterStateSnapshot): Promise<boolean> => {
    return new Promise(resolver => {
      seusService.checkStatus().subscribe({
        next: hasAccess => hasAccess,
        error: error => console.error(error),
      });
      const url = state.url.substring(1).replace(/\?(.*)/g, '');
      checkAuthorization(url, resolver);
    });
  };

  /**
   * Esta funcion valida si un usuario tiene autorizacion a una url y en caso que no la tenga reenvia el flujo a una pantalla
   * de acceso denegado.
   *
   * @param url es la ruta del componente
   * @param resolver permite que se pueda completar el promise.
   */
  const checkAuthorization = (
    url: string,
    resolver: (param: boolean) => void
  ): void => {
    seusService.isAuthorized(url).subscribe({
      next: res => {
        if (!res) {
          router.navigate(['access-denied']);
        }
        resolver(res);
      },
      error: error => {
        router.navigate(['access-denied']);
        console.error(error);
        resolver(false);
      },
    });
  };

  return doActivate(state);
};
