<div class="paginator">
  <span class="mx-1 text-color">Items por página:</span>
  <p-dropdown
    [options]="sizes"
    [(ngModel)]="size"
    (onChange)="handlePageSizeChange()"></p-dropdown>
  <p-paginator
    [first]="(page - 1) * size"
    [rows]="size"
    [totalRecords]="totalItems"
    (onPageChange)="handlePageChange($event)"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first} - {last} de {totalRecords}"
    [showPageLinks]="false"
    [showFirstLastIcon]="false"></p-paginator>
</div>
