import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

type ErrorType =
  | 'forbidden'
  | 'not-found'
  | 'internal-server-error'
  | 'default';

type HttpErrorMessage = {
  code?: number;
  title: string;
  description: string;
};

const ERRORS = {
  forbidden: {
    code: 403,
    title: '¡Acceso denegado!',
    description:
      '¡Lo sentimos! No tienes permisos para ver el recurso al que intentas acceder.',
  },
  'not-found': {
    code: 404,
    title: '¡Ups!',
    description: 'No encontramos lo que buscabas',
  },
  'internal-server-error': {
    code: 500,
    title: '¡Error!',
    description: 'Ocurrió un error en el servidor',
  },
  default: {
    title: '¡Ups!',
    description: 'Ocurrió un error inesperado',
  },
};

@Component({
  selector: 'app-http-error',
  templateUrl: './http-error.component.html',
  styleUrls: ['./http-error.component.scss'],
})
export class HttpErrorComponent {
  errorData: HttpErrorMessage;
  private router: Router = inject(Router);
  private route: ActivatedRoute = inject(ActivatedRoute);

  constructor() {
    const errorType: ErrorType = this.route.snapshot.data.errorType;
    this.errorData = ERRORS[errorType];
  }

  navigateToHome(): void {
    this.router.navigate(['/']);
  }
}
