/**
 * Clase que contiene la información de seguridad de un usuario
 */
export interface IdentityUser {
  isAuthenticated: boolean;
  appTag: string;
  username: string;
  fullName: string;
  dni: string;
  email?: string;
  msg?: string;
  name: string;
}
