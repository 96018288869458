import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import Menu from '../../../inboxes/components/main-menu/menu.json';
import { MenuHeader } from 'src/app/core/menu';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  @Input() menu: MenuHeader[];
  items: MenuItem[];

  ngOnInit(): void {
    this.items = this.menu.map((menuHeader: MenuHeader) => {
      const menuItem: MenuItem = {
        label: menuHeader.name,
        routerLink: menuHeader.menuItems[0].contextPath,
      };
      if (menuHeader.name === 'Bandejas') {
        menuItem.items = Menu;
        menuItem.routerLink = null;
      }
      return menuItem;
    });
  }
}
