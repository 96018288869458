import { Component, inject, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import packageJson from 'package.json';
import { IdentityService, IdentityUser } from '../../../core/security';
import { Router } from '@angular/router';
import { faGear, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  menuItems: MenuItem[];
  user: IdentityUser;
  identityService: IdentityService;
  faGear: IconDefinition = faGear;

  constructor(private router: Router) {
    this.identityService = inject(IdentityService);
  }

  ngOnInit() {
    this.getUserInfo();
  }

  navigateToAddressing() {
    this.router.navigate(['direccionamiento-empresas']);
  }

  getUserInfo(): void {
    this.identityService.getUserInfo().subscribe((userInfo: IdentityUser) => {
      this.identityService.user = userInfo;
      this.user = userInfo;
      this.menuItems = [
        {
          label: this.user.username,
          icon: 'fa-solid fa-user',
          disabled: true,
        },
        {
          label: `Versión ${packageJson.version}`,
          icon: 'fa-solid fa-tag',
          disabled: true,
        },
        {
          label: 'Cerrar sesión',
          icon: 'fa-solid fa-arrow-right-from-bracket',
          command: () => {
            this.logout();
          },
        },
      ];
    });
  }

  logout(): void {
    this.identityService.logout().subscribe((url: string) => {
      window.location.href = url;
    });
  }
}
