import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Location } from '@angular/common';

/**
 * Interceptor HTTP cuya responsabilidad es controlar la autorización y autenticacion global de peticiones hacia el backend
 */
@Injectable({
  providedIn: 'root',
})
export class HttpInterceptRequestService implements HttpInterceptor {
  /**
   * Constructor de la clase
   *
   * @param router objeto para controlar el enrutamiento de la aplicación
   * @param location
   */
  constructor(private router: Router, private location: Location) {}

  /**
   * intercepta las peticiones
   *
   * @param req petición
   * @param next handler para ejecutar la logica cuando se captura la petición
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let headers = new HttpHeaders({ 'X-APP-RELAYSTATE': this.location.path() });
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    headers = headers.append(
      'Access-Control-Allow-Methods',
      'GET,HEAD,OPTIONS,POST,PUT,DELETE'
    );
    const reqWithHeaders = req.clone({ withCredentials: true, headers });
    return next.handle(reqWithHeaders).pipe(
      catchError(err => {
        switch (err.status) {
          case 401:
            document.write(err.error);
            return throwError(() => err);
          case 403:
            this.router.navigate(['/access-denied']);
            return throwError(() => err);
          default:
            return throwError(() => err);
        }
      })
    );
  }
}
