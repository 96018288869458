import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorState } from 'primeng/paginator';

type PaginatorEvent = {
  page: number;
  size: number;
};

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Output() paginatorChange: EventEmitter<PaginatorEvent>;
  @Input() totalItems: number;
  @Input() page: number;
  @Input() size: number = 10;
  sizes: number[] = [10, 20, 50];

  constructor() {
    this.paginatorChange = new EventEmitter<PaginatorEvent>();
  }

  handlePageChange(event: PaginatorState): void {
    this.page = event.page || 0;
    this.paginatorChange.emit({ page: this.page + 1, size: this.size });
  }

  handlePageSizeChange(): void {
    this.page = 1;
    this.paginatorChange.emit({ page: this.page, size: this.size });
  }
}
