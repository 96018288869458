import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PaginatorComponent } from './components/paginator/paginator.component';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { ButtonModule } from 'primeng/button';
import { HttpErrorComponent } from './components/http-error/http-error.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DropdownModule,
    PaginatorModule,
    ButtonModule,
  ],
  declarations: [HttpErrorComponent, PaginatorComponent],
  exports: [HttpErrorComponent, PaginatorComponent],
})
export class SharedModule {}
