<nav class="menu" *ngIf="menu && items">
  <p-scrollPanel>
    <p-panelMenu
      [multiple]="true"
      [model]="items"
      [style]="{
        width: '320px',
        height: 'calc(100vh - 18px)',
        'font-size': '14px'
      }"></p-panelMenu>
  </p-scrollPanel>
</nav>
