import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenuHeader } from 'src/app/core/menu';
import { IdentityService } from 'src/app/core/security';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  sidebarVisible: boolean = true;
  items: MenuItem[];
  userMenu: MenuHeader[];

  constructor(private identityService: IdentityService) {}

  ngOnInit(): void {
    this.identityService.getUserMenu().subscribe(menu => {
      this.userMenu = menu;
    });
  }
}
