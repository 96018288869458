import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  faArrowLeft,
  faArrowRight,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  @Output() toggle: EventEmitter<boolean>;
  @Input() sidebarVisible: boolean = true;
  faArrowLeft: IconDefinition = faArrowLeft;
  faArrowRight: IconDefinition = faArrowRight;

  constructor() {
    this.toggle = new EventEmitter<boolean>();
  }

  toggleSidebar(): void {
    this.sidebarVisible = !this.sidebarVisible;
    this.toggle.emit(this.sidebarVisible);
  }
}
