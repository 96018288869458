/**
 * Constantes de la aplicación
 */
export class Constants {
  /** url para obtener información del usuario */
  static userInfo = 'user/current';

  /** url para concer si un usuario tiene permiso hacia un recurso */
  static userHasAccess = 'user/hasAccess';

  /** url para obtener comprobar que un usuario tenga activa una sesión y en caso contrario
   *  obtener el html del formulario de login
   */
  static userCheckStatus = 'user/check';

  static userLastAccess = 'user/lastAccess';

  static menu = 'menu';

  static logout = 'logout';
}
